import React, {useEffect} from 'react';

const Personalize: React.FC = () => {
  // Set default color scheme
  let scheme = 'white';

  // Retrieve text color and background image from localstorage (otherwise set to default)
  document.body.style.color = localStorage.getItem('txt') || 'black';
  document.body.style.background = localStorage.getItem('bgimg') || scheme;
  document.body.style.backgroundSize = 'cover';

  // Retrieve overlay color from localstorage
  useEffect(() => {
    document.getElementById('overlay')!.style.background =
      localStorage.getItem('bgovr') || 'rgba(255, 255, 255, 0.5)';
  });

  // This function changes the color scheme of the page (text color and background overlay)
  function changeClrScheme(txtcolor: string, bgcolor: string) {
    // Set current color scheme
    scheme = bgcolor;

    // Change text color and background overlay
    document.body.style.color = txtcolor;
    document.getElementById('overlay')!.style.background = scheme;

    // Save in localstorage
    localStorage.setItem('txt', txtcolor);
    localStorage.setItem('bgovr', bgcolor);
  }

  // This function changes the background image of the page
  function changeBgImage(imgpath: string) {
    // Change background image
    document.body.style.background = imgpath;

    // Save in localstorage
    localStorage.setItem('bgimg', imgpath);

    // Cover entire page
    document.body.style.backgroundSize = 'cover';
  }

  return (
    <div
      style={{
        position: 'fixed',
        top: '1px',
        right: '1px',
        margin: '20px',
        width: '200px',
        border: '2px solid #9c9c9c',
        padding: '30px',
      }}
    >
      <h2 style={{margin: '0'}}>Personalize!</h2>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <h3>Colour Scheme</h3>
        <div style={{border: '2px solid #9c9c9c'}}>
          <span
            onClick={() => changeClrScheme('black', 'rgba(255, 255, 255, 0.5)')}
            style={{
              display: 'table-cell',
              width: '50px',
              height: '50px',
              border: '2px solid #9c9c9c',
              background: 'white',
            }}
          ></span>
          <span
            onClick={() => changeClrScheme('white', 'rgba(0, 0, 0, 0.5)')}
            style={{
              display: 'table-cell',
              width: '50px',
              height: '50px',
              border: '2px solid #9c9c9c',
              background: '#333333',
            }}
          ></span>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <h3>Background Image</h3>
        <div style={{border: '2px solid #9c9c9c'}}>
          <span
            onClick={() => changeBgImage('none')}
            style={{
              display: 'table-cell',
              width: '50px',
              height: '50px',
              border: '2px solid #9c9c9c',
              background: '#9c9c9c',
            }}
          >
            None
          </span>
          <span
            onClick={() => changeBgImage("url('images/bgimage1.jpg')")}
            style={{
              display: 'table-cell',
              width: '50px',
              height: '50px',
              border: '2px solid #9c9c9c',
              backgroundImage: "url('images/bgimage1.jpg')",
            }}
          ></span>
          <span
            onClick={() => changeBgImage("url('images/bgimage2.jpg')")}
            style={{
              display: 'table-cell',
              width: '50px',
              height: '50px',
              border: '2px solid #9c9c9c',
              backgroundImage: "url('images/bgimage2.jpg')",
            }}
          ></span>
        </div>
      </div>
    </div>
  );
};

export default Personalize;
