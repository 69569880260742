import React from 'react';
import AddNote from './components/AddNote';
import Personalize from './components/Personalize';
import NoteList from './components/NoteList';
import DateTimeDisplay from './components/DateAndTime';

const App: React.FC = () => {
  return (
    <div className="App">
      <div
        id="overlay"
        style={{height: '100vh', border: '1px solid transparent'}}
      >
        <h1>TODO Notes Application</h1>
        <DateTimeDisplay />
        <AddNote />
        <Personalize />
        <NoteList />
      </div>
    </div>
  );
};

// const App: React.FC = () => {
//   return (
//     <div className="App">
//       <h1>TODO Notes Application</h1>
//     </div>
//   );
// };

export default App;
