import {createTheme, ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import React, {useEffect} from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

const defaultTheme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: '#0A0B0D',
      paper: '#0A0B0D',
    },
  },
});

const fetchWaterData = async () => {
  try {
    // Don't fetch if the database is already populated
    const countResponse = await fetch('/api/waterwise/count');
    const count = await countResponse.json();
    if (count > 0) {
      console.log('Database already has', count, 'rows.');
      return;
    }

    const response = await fetch('/fetch-water-quality', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const data = await response.json();
    if (response.ok) {
      alert('Water Data fetched and added successfully!');
    } else {
      alert(`Error: ${data.detail}`);
    }
  } catch (error) {
    console.error('Error fetching water data:', error);
    alert('Failed to fetch water data.');
  }
};

const Root = () => {
  useEffect(() => {
    fetchWaterData();
  }, []); // Runs only on the first page render

  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline enableColorScheme />
      <App />
    </ThemeProvider>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>,
  document.getElementById('root')
);
