import {GridColDef, GridRowsProp} from '@mui/x-data-grid';

// Fetch drinkingWaterData

export type WaterSample = {
  id: string;
  lake: string;
  stationCode: string;
  station: string;
  sampleDate: string; // Assuming the date is formatted as a string
  granAlkalinity: number;
  ca: number;
  cl: number;
  specificConductance: number;
  dic: number;
  doc: number;
  fe: number;
  potassium: number;
  mg: number;
  na: number;
  nh4Nh3: number;
  no3No2: number;
  tkn: number;
  tn: number;
  ph: number;
  tp: number;
  sio3: number;
  so4: number;
};

export async function getDrinkingWaterData(): Promise<WaterSample[]> {
  const response = await fetch('/api/waterwise/all');
  return await response.json();
}

// The column structure of the DataGrid in the Home page
export const columns: GridColDef[] = [
  {field: 'lake', headerName: 'Lake', flex: 1.5, minWidth: 150},
  {
    field: 'ph',
    headerName: 'pH',
    headerAlign: 'right',
    align: 'right',
    flex: 1,
    minWidth: 50,
  },
  {
    field: 'granAlkalinity',
    headerName: 'Gran Alkalinity (mg/L)',
    headerAlign: 'right',
    align: 'right',
    flex: 1,
    minWidth: 60,
  },
  {
    field: 'ca',
    headerName: 'Ca (mg/L)',
    headerAlign: 'right',
    align: 'right',
    flex: 1,
    minWidth: 50,
  },
  {
    field: 'specificConductance',
    headerName: 'Specific Conductance (μS/cm)',
    headerAlign: 'right',
    align: 'right',
    flex: 1,
    minWidth: 200,
  },
  {
    field: 'fe',
    headerName: 'Fe (mcg/L)',
    headerAlign: 'right',
    align: 'right',
    flex: 1,
    minWidth: 50,
  },
  {
    field: 'na',
    headerName: 'Na (mg/L)',
    headerAlign: 'right',
    align: 'right',
    flex: 1,
    minWidth: 50,
  },
];

async function getLakeData(): Promise<WaterSample[]> {
  const sample = await getDrinkingWaterData();
  return sample.filter(row => row.lake === 'Blue Chalk Lake');
}

export async function getHistoricalPhData() {
  const lakeData = await getLakeData();
  return Array.from({length: lakeData.length}, (_, i) => ({
    date: new Date(lakeData[i].sampleDate).toLocaleDateString(),
    pH: `${lakeData[i].ph}`,
  }));
}

export async function getAverageLakeData() {
  const lakeData = await getLakeData();
  const sumLakeData = {
    Calcium: 0,
    Chlorine: 0,
    Potassium: 0,
    Magnesium: 0,
    Sodium: 0,
  };
  for (const row of lakeData) {
    sumLakeData.Calcium += +row.ca;
    sumLakeData.Chlorine += +row.cl;
    sumLakeData.Potassium += +row.potassium;
    sumLakeData.Magnesium += +row.mg;
    sumLakeData.Sodium += +row.na;
  }
  return [
    {
      name: 'Calcium',
      quantity: +(sumLakeData.Calcium / lakeData.length).toFixed(2),
    },
    {
      name: 'Chlorine',
      quantity: +(sumLakeData.Chlorine / lakeData.length).toFixed(2),
    },
    {
      name: 'Potassium',
      quantity: +(sumLakeData.Potassium / lakeData.length).toFixed(2),
    },
    {
      name: 'Magnesium',
      quantity: +(sumLakeData.Magnesium / lakeData.length).toFixed(2),
    },
    {
      name: 'Sodium',
      quantity: +(sumLakeData.Sodium / lakeData.length).toFixed(2),
    },
  ];
}
