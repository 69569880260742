import React, {useState, useEffect} from 'react';

const DateTimeDisplay: React.FC = () => {
  // State to hold the current date and time
  const [currentDateTime, setCurrentDateTime] = useState(new Date());
  //update the date and time every second
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 1000);

    // Cleanup the interval
    return () => clearInterval(timer);
  }, []);

  return (
    <div>
      {/* render date and time */}
      <p>{currentDateTime.toLocaleString()}</p>
    </div>
  );
};

export default DateTimeDisplay;
