import {Typography} from '@mui/material';
import {styled} from '@mui/material/styles';
import {DataGrid} from '@mui/x-data-grid';
import {
  getDrinkingWaterData,
  columns,
  getHistoricalPhData,
  WaterSample,
  getAverageLakeData,
} from '../internals/data/drinkingWater';
import React, {useEffect, useState} from 'react';
import NumberCard, {NumberCardProps} from './NumberCard';
import Grid from '@mui/material/Grid2';
import LineChartCard from './LineChartCard';
import BarChartCard from './BarChartCard';

// Overridding styles for DataGrid component
const StyledDataGrid = styled(DataGrid)(() => ({
  '& .MuiDataGrid-row': {
    background: '#05070a',
  },
  '& .MuiDataGrid-row:hover': {
    background: '#12151D',
  },
  '& .MuiDataGrid-container--top [role=row]': {
    background: '#0C1017',
  },
}));

async function getAverageParameter(
  parameterName: keyof WaterSample
): Promise<string> {
  let sum = 0;
  const drinkingWaterData = await getDrinkingWaterData();
  for (const row of drinkingWaterData) {
    sum += +row[parameterName];
  }

  return (sum / drinkingWaterData.length).toFixed(2);
}

export default function Home() {
  const [cards, setCards] = useState<NumberCardProps[]>([]);
  const [historicalPhData, setHistoricalPhData] = useState<any[]>([]);
  const [averageLakeData, setAverageLakeData] = useState<any[]>([]);
  const [drinkingWaterData, setDrinkingWaterData] = useState<any[]>([]);

  useEffect(() => {
    Promise.all([
      getAverageParameter('ph'),
      getAverageParameter('ca'),
      getAverageParameter('fe'),
      getAverageParameter('na'),
    ]).then(results => {
      setCards([
        {
          title: 'Average pH',
          value: results[0],
        },
        {
          title: 'Average Calcium',
          value: `${results[1]} mg/L`,
        },
        {
          title: 'Average Iron',
          value: `${results[2]} mcg/L`,
        },
        {
          title: 'Average Sodium',
          value: `${results[3]} mg/L`,
        },
      ]);
    });
    getHistoricalPhData().then(data => setHistoricalPhData(data));
    getAverageLakeData().then(data => setAverageLakeData(data));
    getDrinkingWaterData().then(data => setDrinkingWaterData(data));
  }, []);

  return (
    <>
      <Typography
        component="h2"
        variant="h4"
        sx={{
          mb: 3,
          fontWeight: 'bold',
          color: 'primary.main',
          textAlign: 'center',
          textShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)',
          letterSpacing: '0.1em',
        }}
      >
        WaterWise
      </Typography>

      <Typography component="h2" variant="h6" sx={{mb: 2}}>
        Overview
      </Typography>
      <Grid
        container
        spacing={2}
        columns={12}
        sx={{mb: theme => theme.spacing(2)}}
      >
        {cards.map((card, index) => {
          return (
            <Grid key={index} size={{xs: 12, sm: 6, lg: 3}}>
              <NumberCard title={card.title} value={card.value}></NumberCard>
            </Grid>
          );
        })}
        <Grid size={{sm: 12, md: 6}}>
          <LineChartCard
            title={'Historical Water pH (Blue Chalk Lake)'}
            data={historicalPhData}
          />
        </Grid>
        <Grid size={{sm: 12, md: 6}}>
          <BarChartCard
            title={'Pollutant Levels (mg/L)'}
            data={averageLakeData}
          />
        </Grid>
      </Grid>
      <Typography component="h2" variant="h6" sx={{mb: 2}}>
        Details
      </Typography>
      <StyledDataGrid
        autoHeight
        // TODO: Get drinking water data from backend call instead of a temp file
        rows={drinkingWaterData}
        columns={columns}
        initialState={{
          pagination: {paginationModel: {pageSize: 20}},
        }}
        pageSizeOptions={[10, 20, 50]}
        disableColumnFilter
      />
    </>
  );
}
